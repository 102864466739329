import React, { useEffect, useState } from "react";
import { Grid, Button, Paper } from "@material-ui/core";
import Controls from "./Controls";
import MonthlyTable from "./MonthlyTable";
import {
  getMonthEventsForAll,
  getWeekEventsForAll,
  getRangeEventsForAll,
  getDownloadUrl
} from "../../../services/eventService";
import { dateToDayMonthYear } from "../../../utils/dates";
import { toast } from "react-toastify";

function BZ() {
  const [events, setEvents] = useState([]);
  const [days, setDays] = useState([]);
  const [selected, setSelected] = useState(1);
  const [date, setDate] = useState({
    month: new Date(),
    week: new Date(),
    rangeFrom: new Date(),
    rangeTo: new Date()
  });

  const onDateChange = (name, newDate) => {
    setDate({ ...date, [name]: newDate });
  };

  const fetchEventsForMonth = async () => {
    try {
      const { month, year } = dateToDayMonthYear(date.month);
      const { days, events } = await getMonthEventsForAll(month, year);
      setDays(days);
      setEvents(events);
    } catch (error) {
      console.log(error);
      toast.error("Coś poszło nie tak.");
    }
  };

  const fetchEventsForWeek = async () => {
    try {
      const { day, month, year } = dateToDayMonthYear(date.week);
      const { days, events } = await getWeekEventsForAll(day, month, year);
      setDays(days);
      setEvents(events);
    } catch (error) {
      console.log(error);
      toast.error("Coś poszło nie tak.");
    }
  };

  const fetchEventsForRange = async () => {
    try {
      const {
        day: dayFrom,
        month: monthFrom,
        year: yearFrom
      } = dateToDayMonthYear(date.rangeFrom);
      const { day: dayTo, month: monthTo, year: yearTo } = dateToDayMonthYear(
        date.rangeTo
      );

      const { days, events } = await getRangeEventsForAll(
        dayFrom,
        monthFrom,
        yearFrom,
        dayTo,
        monthTo,
        yearTo
      );
      setDays(days);
      setEvents(events);
    } catch (error) {
      console.log(error);
      toast.error("Coś poszło nie tak.");
    }
  };

  const fetchData = () => {
    switch (selected) {
      case 0:
        fetchEventsForMonth();
        break;
      case 1:
        fetchEventsForWeek();
        break;
      case 2:
        fetchEventsForRange();
        break;
      default:
        break;
    }
  };

  const handleDownloadTable = () => {
    const { day, month, year } = dateToDayMonthYear(date.week);
    const url = getDownloadUrl(day, month, year, 0);
    window.open(url, "_blank");
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, date]);

  const renderTable = () => {
    switch (selected) {
      case 0:
        return (
          <MonthlyTable title="Wykaz Miesięczny" days={days} events={events} />
        );
      case 1:
        return (
          <MonthlyTable title="Wykaz Tygodniowy" days={days} events={events} />
        );
      case 2:
        return <MonthlyTable title="Wykaz Od Do" days={days} events={events} />;
      default:
        return null;
    }
  };

  return (
    <Paper square style={{ padding: 20 }}>
      <Grid container direction="column" spacing={3}>
        <Controls
          setSelected={setSelected}
          selected={selected}
          date={date}
          onDateChange={onDateChange}
        />
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            onClick={handleDownloadTable}
          >
            Eksportuj
          </Button>
        </Grid>
        <Grid item style={{ maxWidth: "100%" }}>
          {renderTable()}
        </Grid>
      </Grid>
    </Paper>
  );
}

export default BZ;
