import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Grid } from "@material-ui/core";
import Select from "../../common/Select";
import { addWorker, updateWorker } from "../../../services/userService";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";

const defaultWorkerData = {
  first_name: "",
  last_name: "",
  email_address: "",
  phone_number: "",
  card_number: "",
  department: "",
  position: "",
  user: ""
};

export default function AddWorkerDialog({
  open,
  setOpen,
  departments = [],
  users = [],
  workerData = defaultWorkerData,
  isEditing = false,
  fetchUsersWithRoles,
  fetchUser,
  workerId
}) {
  let newData = { ...defaultWorkerData };
  if (Object.values(workerData).length > 0) {
    newData = { ...workerData };
  }
  if (workerData.department && workerData.department.id) {
    const department = departments.find(d => d.id === workerData.department.id);
    newData.department = department.id;
  }
  if (workerData.user && workerData.user.id) {
    const user = users.find(u => u.id === workerData.user.id);
    newData.user = user.id;
  }
  const [worker, setWorker] = useState({ ...newData });
  const serializedData = JSON.stringify(newData);
  const { register, errors, handleSubmit, control } = useForm({
    mode: "onChange",
    defaultValues: {
      ...newData
    }
  });

  useEffect(() => {
    setWorker(newData);
  }, [serializedData]);

  if (!open) {
    return null;
  }

  const handleClose = () => {
    setOpen(null);
  };

  const addNewUser = async worker => {
    try {
      if (worker.phone_number === "") {
        worker.phone_number = null;
      }
      await addWorker(worker);
      setWorker({ ...workerData });
      fetchUsersWithRoles();
      toast.success("Dodano pracownika");
      handleClose();
    } catch (error) {
      console.log(error);
      toast.error("Coś poszło nie tak.");
    }
  };

  const updateUser = async worker => {
    try {
      if (worker.phone_number === "") {
        worker.phone_number = null;
      }
      await updateWorker(worker, workerId);
      setWorker({ ...workerData });
      toast.success("Zaktualizowano pracownika");
      fetchUser();
      handleClose();
    } catch (error) {
      console.log(error);
      toast.error("Coś poszło nie tak.");
    }
  };

  const onSubmit = worker => {
    if (isEditing) {
      updateUser(worker);
    } else {
      addNewUser(worker);
    }
  };

  const handleArchiveWorker = async () => {
    try {
      delete worker.project;
      worker.is_active = false;
      await updateWorker(worker);
      setWorker({ ...workerData });
      toast.success("Zarchiwizowano pracownika");
      fetchUser();
      handleClose();
    } catch (error) {
      console.log(error);
      toast.error("Coś poszło nie tak.");
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {isEditing ? "Edytuj Pracownika" : "Dodaj Pracownika"}
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent style={{ overflowY: "hidden" }}>
          <Grid
            container
            spacing={2}
            justify="space-between"
            direction="column"
          >
            <Grid container item spacing={3}>
              <Grid item>
                <TextField
                  inputRef={register({ required: "Pole nie może być puste" })}
                  autoFocus
                  label="Imię"
                  fullWidth
                  name="first_name"
                  error={Boolean(errors["first_name"])}
                  helperText={
                    errors["first_name"] && errors["first_name"].message
                  }
                />
              </Grid>
              <Grid item>
                <TextField
                  inputRef={register({ required: "Pole nie może być puste" })}
                  label="Nazwisko"
                  fullWidth
                  name="last_name"
                  error={Boolean(errors["last_name"])}
                  helperText={
                    errors["last_name"] && errors["last_name"].message
                  }
                />
              </Grid>
            </Grid>
            <Grid container item spacing={3}>
              <Grid item>
                <TextField
                  inputRef={register({
                    required: "Pole nie może być puste",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Nieprawidłowy format email"
                    }
                  })}
                  label="E-mail"
                  fullWidth
                  name="email_address"
                  error={Boolean(errors["email_address"])}
                  helperText={
                    errors["email_address"] && errors["email_address"].message
                  }
                />
              </Grid>
              <Grid item>
                <TextField
                  inputRef={register({
                    max: 2147483647
                  })}
                  label="Numer telefonu"
                  fullWidth
                  name="phone_number"
                  error={Boolean(errors["phone_number"])}
                  type="number"
                  helperText={
                    errors["phone_number"] && errors["phone_number"].message
                  }
                />
              </Grid>
            </Grid>
            <Grid container item spacing={3}>
              <Grid item>
                <TextField
                  inputRef={register({ required: "Pole nie może być puste" })}
                  label="Stanowisko"
                  fullWidth
                  name="position"
                  error={Boolean(errors["position"])}
                  helperText={errors["position"] && errors["position"].message}
                />
              </Grid>
              <Grid item>
                <Controller
                  as={TextField}
                  name="card_number"
                  label="Numer karty"
                  fullWidth
                  rules={{ required: "Pole nie może być puste" }}
                  onChange={([e]) => e.target.value.toUpperCase()}
                  control={control}
                  error={Boolean(errors["card_number"])}
                  helperText={
                    errors["card_number"] && errors["card_number"].message
                  }
                />
              </Grid>
            </Grid>
            <Grid container item spacing={3}>
              <Grid item xs={6}>
                <Controller
                  as={Select}
                  name="department"
                  label="Dział"
                  items={departments}
                  itemValueProp="id"
                  itemLabelProp="department"
                  rules={{ required: "Pole nie może być puste" }}
                  onChange={([e]) => e.target.value}
                  control={control}
                  error={Boolean(errors["department"])}
                  errorText={
                    errors["department"] && errors["department"].message
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  as={Select}
                  name="user"
                  label="Nazwa użytkownika"
                  items={users}
                  itemValueProp="id"
                  itemLabelProp="username"
                  onChange={([e]) => e.target.value}
                  control={control}
                  error={Boolean(errors["user"])}
                  errorText={errors["user"] && errors["user"].message}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justify="space-between">
            <Grid item>
              {isEditing && (
                <Button
                  onClick={handleArchiveWorker}
                  color="primary"
                  variant="outlined"
                >
                  Archiwizuj pracownika
                </Button>
              )}
            </Grid>
            <Grid item>
              <Button onClick={handleClose} color="primary">
                Anuluj
              </Button>
              <Button type="submit" color="primary">
                {isEditing ? "Zapisz" : "Dodaj"}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
}
