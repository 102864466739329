import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Grid } from "@material-ui/core";
import Select from "../../common/Select";
import { addZone } from "../../../services/accessService";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import Checkbox from '@material-ui/core/Checkbox';

const defaultZoneData = {
    "zone": "",
    "reader": ""
};

export default function AddZoneDialog({
    open,
    setOpen,
    zoneData = defaultZoneData,
    fetchZones,
    zones = [],
    readers = [],
    isEditing = false
}) {
    let newData = { ...defaultZoneData };
    if (Object.values(zoneData).length > 0) {
        newData = { ...zoneData };
      }
    if (zoneData.reader && zoneData.reader.id) {
        const reader = readers.find(r => r.id === zoneData.reader.id);
        newData.reader = reader.id;
    }   
    const [zone, setZone] = useState({ ...newData });
    const serializedData = JSON.stringify(newData);
    const { register, errors, handleSubmit, control } = useForm({
        mode: "onChange",
        defaultValues: {
          ...newData
        }
    });
    useEffect(() => {
        setZone(newData);
      }, [serializedData]);

    if (!open) {
        return null;
      }
    const handleClose = () => {
        setOpen(null);
    };

    const addNewZone = async zone => {
        try {
          await addZone(zone);
          setZone({ ...zoneData });
          fetchZones();
          toast.success("Dodano strefę");
          handleClose();
        } catch (error) {
          console.log(error);
          toast.error("Coś poszło nie tak.");
        }
      };

    const onSubmit = zone => {
        addNewZone(zone);
    };
    return (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            {isEditing ? "Edytuj Strefę" : "Dodaj Strefę"}
          </DialogTitle>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent style={{ overflowY: "hidden" }}>
              <Grid
                container
                spacing={2}
                justify="space-between"
                direction="column"
              > 
                <Grid container item spacing={3}>
                  <Grid item>
                    {(zones || []).map(zone => {
                        return(
                            <label>
                            {zone.zone}
                            <Checkbox
                                value={zone.id}
                                inputProps={zone.zone}
                            />
                            </label>
                        )
                    }
                    )}
                  </Grid>
                  
                </Grid>
                <Grid container item spacing={3}>
                <Grid item>
                    <Controller
                        as={Select}
                        name="reader"
                        label="Czytnik"
                        items={readers}
                        itemValueProp="id"
                        itemLabelProp="reader_name"
                        rules={{ required: "Pole nie może być puste" }}
                        onChange={([e]) => e.target.value}
                        control={control}
                        error={Boolean(errors["reader"])}
                        errorText={
                            errors["reader"] && errors["reader"].message
                        }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid container justify="space-between">
                <Grid item>
                  {isEditing && (
                    <Button
                      //onClick={}
                      color="primary"
                      variant="outlined"
                    >
                      Archiwizuj pracownika
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  <Button onClick={handleClose} color="primary">
                    Anuluj
                  </Button>
                  <Button type="submit" color="primary">
                    {isEditing ? "Zapisz" : "Dodaj"}
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </form>
        </Dialog>
      );
    }