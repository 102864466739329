import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  DialogActions,
  Button
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Select from "../../common/Select";

function AssignManager({ open, handleClose, saveProject, project, managers }) {
  const [newProject, setNewProject] = useState({ ...project });

  useEffect(() => {
    setNewProject({ ...project });
  }, [project]);

  const handleInputChange = e => {
    const { name, value } = e.target;
    setNewProject({ ...newProject, [name]: value });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Przypisz kierownika</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} justify="space-between" direction="column">
          <Grid item>
            <Select
              onChange={handleInputChange}
              name="manager"
              label="Kierownik"
              value={newProject.manager || ""}
              items={managers}
              itemValueProp="worker_id"
              customLabel={item => `${item.first_name} ${item.last_name}`}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Anuluj
        </Button>
        <Button onClick={() => saveProject(newProject)} color="primary">
          Zapisz
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AssignManager;
