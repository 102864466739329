import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  DialogActions,
  Button
} from "@material-ui/core";
import React, { useState } from "react";
import { toast } from "react-toastify";

function NewProject({ open, handleClose, addNewProject }) {
  const [newProject, setNewProject] = useState({
    project: ""
  });

  const handleInputChange = e => {
    const { name, value } = e.target;
    setNewProject({ ...newProject, [name]: value });
  };

  const handleAdd = async () => {
    try {
      await addNewProject(newProject.project);
      setNewProject("");
      handleClose();
    } catch (error) {
      console.log(error);
      toast.error("Coś poszło nie tak.");
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      keepMounted
    >
      <DialogTitle id="form-dialog-title">Dodaj Budowę</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} justify="space-between" direction="column">
          <TextField
            label="Nazwa Budowy"
            name="project"
            value={newProject.project}
            onChange={handleInputChange}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Anuluj
        </Button>
        <Button onClick={handleAdd} color="primary">
          Dodaj
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default NewProject;
