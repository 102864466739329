import React from "react";
import { ResponsivePie } from "@nivo/pie";

function HoursChart({ total_hours, total_over_time }) {
  let data = [
    {
      id: "Godziny podstawowe",
      label: "Godziny podstawowe",
      value: total_hours,
      color: "hsl(116, 70%, 50%)"
    },
    {
      id: "Nadgodziny",
      label: "Nadgodziny",
      value: total_over_time,
      color: "hsl(162, 70%, 50%)"
    }
  ];
  data = data.filter(item => item.value > 0);

  return (
    <ResponsivePie
      data={data}
      colors={{ scheme: "paired" }}
      margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
    />
  );
}

export default HoursChart;
