import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from "@material-ui/core";
import Select from "../../common/Select";
import { updateWorker } from "../../../services/userService";
import { toast } from "react-toastify";

function AssignProject({
  editedUser,
  handleClose,
  assignableProjects,
  fetchWorkers
}) {
  const [user, setUser] = useState(editedUser);

  const handleProjectChange = e => {
    const { value } = e.target;
    const project = assignableProjects.find(p => p.id === value);
    setUser({ ...user, project });
  };

  const selectValue = user.project && user.project.id;

  const assignProject = async () => {
    try {
      const newUser = { ...user };
      newUser.project = newUser.project.id;
      newUser.department = newUser.department.id;
      delete newUser.user;
      await updateWorker(newUser, newUser.id);
      toast.success("Przypisano budowę");
      fetchWorkers();
      handleClose();
    } catch (error) {
      console.log(error);
      toast.error("Coś poszło nie tak.");
    }
  };

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Przypisz Budowę</DialogTitle>
      <DialogContent>
        <Select
          onChange={handleProjectChange}
          label="Budowa"
          value={selectValue || ""}
          items={assignableProjects}
          itemValueProp="id"
          itemLabelProp="project"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Anuluj
        </Button>
        <Button onClick={assignProject} color="primary">
          Zapisz
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AssignProject;
