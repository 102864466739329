import React from "react";
import AlarmOn from "@material-ui/icons/AlarmOn";
import { Paper, Typography } from "@material-ui/core";
import "./Home.css";
import { useHistory } from "react-router-dom";

function Home() {
  const history = useHistory();

  return (
    <div>
      <Paper className="Home-card" onClick={() => history.push("/bz")}>
        <AlarmOn style={{ fontSize: "4em" }} />
        <Typography variant="h2" component="p">
          RCP
        </Typography>
      </Paper>
    </div>
  );
}

export default Home;
