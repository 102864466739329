import React from "react";
import { useEffect } from "react";
import { getOnlineUsers } from "../../../services/userService";
import { getAccessZone } from "../../../services/accessService";
import { useState } from "react";
import { makeStyles, Typography, Paper, Grid } from "@material-ui/core";
import Person from "../../../static/images/person.png";
import { dateToDayMonthYear } from "../../../utils/dates";
import { differenceInHours } from "date-fns";
import { toast } from "react-toastify";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  departmentHeading: {
    margin: "10px 0"
  },
  personCard_container: {
    maxWidth: 350
  },
  personCard_image: {
    maxWidth: 200,
    height: "auto"
  },
  department_container: {
    padding: 20
  }
}));

function PeopleOnline() {
  const [departments, setDepartments] = useState([]);
  const classes = useStyles();

  const someoneOnline = departments.some(dep => dep.people.length > 0);

  const fetchData = async () => {
    try {
      const response = await getOnlineUsers();
      setDepartments(response.data);
    } catch (error) {
      console.log(error);
      toast.error("Coś poszło nie tak.");
    }
  };

  const access = async () => {
    try{
      const response = await getAccessZone();
      console.log(response);
    } catch (error) {
      console.log(error);
      toast.error("problem z accessem");
    }
  };

  useEffect(() => {
    fetchData();
    access();
  }, []);

  const getTimeInWork = time_in => {
    if (!time_in) {
      return "";
    }
    const now = new Date();
    const { day, month, year } = dateToDayMonthYear(now);
    const [h, m, s] = time_in.split(":");
    const timeIn = new Date(year, month - 1, day, h, m, s);
    const diff = differenceInHours(now, timeIn);
    return diff;
  };
  return (
    <div className={classes.root}>
      <Typography variant="h4" style={{ marginBottom: 20 }}>
        Pracownicy Online
      </Typography>
      {!someoneOnline && (
        <Typography variant="h6">Brak pracowników online</Typography>
      )}
      <Grid container direction="column" spacing={4}>
        {(departments || []).map(department => {
          if (department.people.length === 0) {
            return null;
          }

          return (
            <Grid item>
              <Paper square className={classes.department_container}>
                <Typography
                  key={department.department}
                  variant="h6"
                  className={classes.departmentHeading}
                  align="center"
                >
                  {department.department}
                </Typography>
                <Grid container spacing={3}>
                  {department.people.map(person => (
                    <Grid item key={person.id}>
                      <Paper
                        className={classes.personCard_container}
                        square
                        elevation={4}
                      >
                        <Grid container direction="column">
                          <Grid item>
                            <img
                              src={Person}
                              alt="person"
                              className={classes.personCard_image}
                            ></img>
                          </Grid>
                          <Grid item>
                            <Typography variant="h6" align="center">
                              {person.first_name + " " + person.last_name}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography align="center">
                              Godzina wejścia: {person.time_in}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography align="center">
                              Czas w pracy: {getTimeInWork(person.time_in)}h
                            </Typography>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}

export default PeopleOnline;
