import axios from "axios";
import { logout } from "./services/authenticationService";

axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = "Token " + token;
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => response,
  error => {
    const { status } = error.response;
    if (status === 401) {
      logout();
      window.location("/");
      return Promise.resolve({});
    }
    return Promise.reject(error);
  }
);
