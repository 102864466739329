import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Grid } from "@material-ui/core";
import { addUser } from "../../../services/userService";
import Select from "../../common/Select";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";

const userData = {
  username: "",
  password: "",
  role: ""
};

export default function AddUserDialog({
  open,
  setOpen,
  fetchUsers,
  roles,
  fetchUsersWithRoles
}) {
  const {
    register,
    errors,
    handleSubmit,
    control,
    formState,
    clearError
  } = useForm({
    mode: "onChange"
  });
  if (!open) {
    return null;
  }

  const handleClose = () => {
    setOpen(null);
    clearError();
  };

  const onSubmit = async user => {
    try {
      if (!formState.isValid || user.target) {
        return;
      }
      const newUser = { ...user };
      newUser.groups = [user.role];
      await addUser(newUser);
      fetchUsers();
      fetchUsersWithRoles();
      toast.success("Dodano użytkownika");
      handleClose(null);
    } catch (error) {
      console.log(error);
      toast.error("Coś poszło nie tak.");
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      keepMounted
    >
      <DialogTitle id="form-dialog-title">Dodaj Użytkownika</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent style={{ overflowY: "hidden" }}>
          <Grid
            container
            justify="space-between"
            direction="column"
            spacing={3}
          >
            <Grid container item spacing={3}>
              <Grid item>
                <TextField
                  inputRef={register({ required: "Pole nie może być puste" })}
                  label="Nazwa użytkownika"
                  fullWidth
                  name="username"
                  error={Boolean(errors["username"])}
                  helperText={errors["username"] && errors["username"].message}
                />
              </Grid>
              <Grid item>
                <TextField
                  inputRef={register({ required: "Pole nie może być puste" })}
                  label="Hasło"
                  type="password"
                  fullWidth
                  name="password"
                  error={Boolean(errors["password"])}
                  helperText={errors["password"] && errors["password"].message}
                />
              </Grid>
            </Grid>
            <Grid container item spacing={3}>
              <Grid item>
                <Controller
                  as={Select}
                  name="role"
                  label="Uprawnienia"
                  items={roles}
                  itemValueProp="id"
                  itemLabelProp="label"
                  rules={{ required: "Pole nie może być puste" }}
                  onChange={([e]) => {
                    return e.target.value;
                  }}
                  control={control}
                  defaultValue={null}
                  error={Boolean(errors["role"])}
                  errorText={errors["role"] && errors["role"].message}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Anuluj
          </Button>
          <Button onClick={onSubmit} color="primary" type="submit">
            Dodaj
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
