import React from "react";
import DefaultTable from "../../common/DefaultTable";

function UsersWithRolesTable({ usersWithRoles }) {
  const columns = [
    {
      title: "Użytkownik",
      field: "username"
    },
    {
      title: "Pracownik",
      field: "restriction",
      render: rowData =>
        rowData.first_name ? `${rowData.first_name} ${rowData.last_name}` : ""
    },
    {
      title: "Uprawnienie",
      field: "label"
    }
  ];

  return <DefaultTable columns={columns} data={usersWithRoles} />;
}

export default UsersWithRolesTable;
