import React from "react";
import { Grid, Button, Paper } from "@material-ui/core";
import moment from "moment";
import { format } from "date-fns";
import plLocale from "date-fns/locale/pl";

function WorklogToolbar({ handleDateChange, date }) {
  const goBefore = days => {
    const newDate = moment()
      .subtract({ days })
      .toDate();
    handleDateChange(newDate);
  };
  const goToday = () => {
    handleDateChange(new Date());
  };

  return (
    <Grid item container justify="space-between">
      <Grid item container spacing={1} xs={8}>
        <Grid item>
          <Button
            style={{ minWidth: 100 }}
            variant="outlined"
            onClick={() => goBefore(3)}
          >
            3 dni temu
          </Button>
        </Grid>
        <Grid item>
          <Button
            style={{ minWidth: 100 }}
            variant="outlined"
            onClick={() => goBefore(2)}
          >
            Przedwczoraj
          </Button>
        </Grid>
        <Grid item>
          <Button
            style={{ minWidth: 100 }}
            variant="outlined"
            onClick={() => goBefore(1)}
          >
            Wczoraj
          </Button>
        </Grid>
        <Grid item>
          <Button
            style={{ minWidth: 100 }}
            variant="outlined"
            onClick={goToday}
          >
            Dzisiaj
          </Button>
        </Grid>
      </Grid>
      <Grid item style={{ fontSize: "1.8em", textAlign: "right" }} xs={3}>
        <Paper elevation={3} square style={{ padding: 4, textAlign: "center" }}>
          {format(date, "cccc d MMMM y", { locale: plLocale })}
        </Paper>
      </Grid>
    </Grid>
  );
}

export default WorklogToolbar;
