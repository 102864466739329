import { API } from "../constants";
import axios from "axios";
import { toast } from "react-toastify";

export const getDepartments = async () => {
  try {
    const response = await axios.get(API.DEPARTMENT);
    return response.data;
  } catch (error) {
    console.log(error);
    toast.error("Coś poszło nie tak.");
    throw error;
  }
};

export const addDepartment = async department => {
  try {
    const response = await axios.post(API.DEPARTMENT, department);
    return response.data;
  } catch (error) {
    console.log(error);
    toast.error("Coś poszło nie tak.");
    throw error;
  }
};

export const getProjects = async isActive => {
  try {
    const response = await axios.get(
      `${API.PROJECT}${isActive ? "active/" : "inactive/"}`
    );
    return response.data;
  } catch (error) {
    console.log(error);
    toast.error("Coś poszło nie tak.");
    throw error;
  }
};
