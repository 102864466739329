import React from "react";
import { Grid, MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import TooltipIconButton from "../../common/TooltipIconButton";
import DefaultTable from "../../common/DefaultTable";

const tableTheme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {
        padding: 8
      }
    }
  }
});

function ProjectsTable({
  projects,
  openEditProjectDialog,
  openAssignManagerDialog,
  setProjectActive,
  isActive,
  managers
}) {
  const columns = [
    {
      title: "Budowa",
      field: "project"
    },
    {
      title: "Kierownik",
      field: "manager",
      render: rowData => {
        if (rowData.manager === null) {
          return "";
        }
        const manager = (managers || []).find(
          manager => manager.worker_id === rowData.manager
        );
        return manager ? manager.first_name + " " + manager.last_name : "";
      }
    },
    {
      title: "Akcje",
      field: "id",
      render: rowData => (
        <Grid container spacing={2}>
          {isActive && (
            <Grid item>
              <TooltipIconButton
                title="Dodaj kierownika"
                iconName="person_add"
                onClick={() => openAssignManagerDialog(rowData)}
              />
            </Grid>
          )}
          {isActive && (
            <Grid item>
              <TooltipIconButton
                title="Edytuj budowę"
                iconName="edit"
                onClick={() => openEditProjectDialog(rowData)}
              />
            </Grid>
          )}
          {isActive && (
            <Grid item>
              <TooltipIconButton
                title="Archiwizuj"
                iconName="archive"
                onClick={() => setProjectActive(rowData, false)}
              />
            </Grid>
          )}
          {!isActive && (
            <Grid item>
              <TooltipIconButton
                title="Aktywuj budowę"
                iconName="archive"
                onClick={() => setProjectActive(rowData, true)}
              />
            </Grid>
          )}
        </Grid>
      )
    }
  ];

  return (
    <MuiThemeProvider theme={tableTheme}>
      <DefaultTable
        columns={columns}
        data={projects}
        title=""
        style={{ minWidth: "80%" }}
      />
    </MuiThemeProvider>
  );
}

export default ProjectsTable;
