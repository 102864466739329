import { API } from "../constants";
import axios from "axios";
import { getToken } from "./authenticationService";
import { toast } from "react-toastify";

const transformEventsResponse = response => {
  const events = Object.values(response.data);
  const days = Object.values(events.pop());
  return { events, days };
};

export const getMonthEventsForAll = async (month, year) => {
  try {
    const response = await getEvents(0, month, year, 0);
    const { events, days } = transformEventsResponse(response);
    return { events, days };
  } catch (error) {
    console.log(error);
    toast.error("Coś poszło nie tak.");
    throw error;
  }
};

export const getMonthEventsForEmployee = async (month, year, id) => {
  try {
    const response = await getEvents(0, month, year, id);
    const { events, days } = transformEventsResponse(response);
    return { events, days };
  } catch (error) {
    console.log(error);
    toast.error("Coś poszło nie tak.");
    throw error;
  }
};

export const getWeekEventsForAll = async (day, month, year) => {
  try {
    const response = await getEvents(day, month, year, 0);
    const { events, days } = transformEventsResponse(response);
    return { events, days };
  } catch (error) {
    console.log(error);
    toast.error("Coś poszło nie tak.");
    throw error;
  }
};

export const getRangeEventsForAll = async (
  dayFrom,
  monthFrom,
  yearFrom,
  dayTo,
  monthTo,
  yearTo
) => {
  try {
    const response = await getRangeEvents(
      dayFrom,
      monthFrom,
      yearFrom,
      dayTo,
      monthTo,
      yearTo
    );
    const { events, days } = transformEventsResponse(response);
    return { events, days };
  } catch (error) {
    console.log(error);
    toast.error("Coś poszło nie tak.");
    throw error;
  }
};

const getRangeEvents = (
  dayFrom,
  monthFrom,
  yearFrom,
  dayTo,
  monthTo,
  yearTo
) => {
  return axios.get(
    `${API.EVENT}day/${dayFrom}/month/${monthFrom}/year/${yearFrom}/endday/${dayTo}/endmonth/${monthTo}/endyear/${yearTo}`
  );
};

const getEvents = (day, month, year, employeeId) => {
  return axios.get(
    `${API.EVENT}day/${day}/month/${month}/year/${year}/id/${employeeId}/`
  );
};

export const getDownloadUrl = (day, month, year, employeeId) =>
  `${
    API.EVENT
  }day/${day}/month/${month}/year/${year}/id/${employeeId}/download/?token=${getToken()}`;
