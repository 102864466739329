/*export const API = {
  AUTH: "http://192.168.1.158:8000/api/auth/",
  EVENT: "http://192.168.1.158:8000/api/event/",
  DEPARTMENT: "http://192.168.1.158:8000/api/department/",
  PROJECT: "http://192.168.1.158:8000/api/project/",
  USER: "http://192.168.1.158:8000/api/user/",
  PEOPLE: "http://192.168.1.158:8000/api/people/",
  CALENDAR: "http://192.168.1.158:8000/api/people/monthtime/",
  WORKLOG: "http://192.168.1.158:8000/api/people/subordinates/day/",
  ABSENCE_CALENDAR: "http://192.168.1.158:8000/api/calendar/",
  PEOPLE_ONLINE: "http://192.168.1.158:8000/api/people/online/",
  STATISTICS: "http://192.168.1.158:8000/api/stats/",
  ROLES: "http://192.168.1.158:8000/api/group/role/",
  GROUP: "http://192.168.1.158:8000/api/group/",
  ACCESS: "http://192.168.1.158:8000/api/accesscontrol/"
};

export const API = {
  AUTH: "https://rcp.keysys.pl/api/auth/",
  EVENT: "https://rcp.keysys.pl/api/event/",
  DEPARTMENT: "https://rcp.keysys.pl/api/department/",
  PROJECT: "https://rcp.keysys.pl/api/project/",
  USER: "https://rcp.keysys.pl/api/user/",
  PEOPLE: "https://rcp.keysys.pl/api/people/",
  CALENDAR: "https://rcp.keysys.pl/api/people/monthtime/",
  WORKLOG: "https://rcp.keysys.pl/api/people/subordinates/day/",
  ABSENCE_CALENDAR: "https://rcp.keysys.pl/api/calendar/",
  PEOPLE_ONLINE: "https://rcp.keysys.pl/api/people/online/",
  STATISTICS: "https://rcp.keysys.pl/api/stats/",
  ROLES: "https://rcp.keysys.pl/api/group/role/",
  GROUP: "https://rcp.keysys.pl/api/group/",
  ACCESS: "https://rcp.keysys.pl/api/accesscontrol/",
  READERS: "https://rcp.keysys.pl/api/readers/",
};

export const API = {
  AUTH: "http://127.0.0.1:8000/api/auth/",
  EVENT: "http://127.0.0.1:8000/api/event/",
  DEPARTMENT: "http://127.0.0.1:8000/api/department/",
  PROJECT: "http://127.0.0.1:8000/api/project/",
  USER: "http://127.0.0.1:8000/api/user/",
  PEOPLE: "http://127.0.0.1:8000/api/people/",
  CALENDAR: "http://127.0.0.1:8000/api/people/monthtime/",
  WORKLOG: "http://127.0.0.1:8000/api/people/subordinates/day/",
  ABSENCE_CALENDAR: "http://127.0.0.1:8000/api/calendar/",
  PEOPLE_ONLINE: "http://127.0.0.1:8000/api/people/online/",
  STATISTICS: "http://127.0.0.1:8000/api/stats/",
  ROLES: "http://127.0.0.1:8000/api/group/role/",
  GROUP: "http://127.0.0.1:8000/api/group/",
  ACCESS: "http://127.0.0.1:8000/api/accesscontrol/",
  READERS: "http://127.0.0.1:8000/api/readers/",
};
*/
export const API = {
  AUTH: "/api/auth/",
  EVENT: "/api/event/",
  DEPARTMENT: "/api/department/",
  PROJECT: "/api/project/",
  USER: "/api/user/",
  PEOPLE: "/api/people/",
  CALENDAR: "/api/people/monthtime/",
  WORKLOG: "/api/people/subordinates/day/",
  ABSENCE_CALENDAR: "/api/calendar/",
  PEOPLE_ONLINE: "/api/people/online/",
  STATISTICS: "/api/stats/",
  ROLES: "/api/group/role/",
  GROUP: "/api/group/",
  ACCESS: "/api/accesscontrol/",
  READERS: "/api/readers/",
};